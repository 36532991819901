<template>
  <div>
    <el-dialog title="商家余额退款" :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <el-form ref="form" :model="formInline" :rules="rules">
          <el-form-item :label="$t('searchModule.Refund_method')" prop="radio">
            <el-radio-group v-model="formInline.radio">
              <el-radio :label="3">系统退款</el-radio>
              <el-radio :label="6">人工退款</el-radio>
            </el-radio-group>
          </el-form-item>
          <table class="table" v-if="formInline.radio == 3">
            <thead>
              <tr>
                <th>序号</th>
                <th>充值金额</th>
                <th>支付渠道</th>
                <th>充值时间</th>
                <th>退款金额</th>
              </tr>
            </thead>
            <tbody class="tableBody">
              <tr v-for="(item, index) in orderList" :key="index">
                <td class="tableTd">{{ index + 1 }}</td>
                <td class="tableTd">{{ item.transactionMoney }}元</td>
                <td class="tableTd">{{ item.payChannel }}</td>
                <td class="tableTd">{{ item.transactionTime }}</td>
                <td class="tableTd">
                  <!-- <el-form-item
                    :prop="'orderList.' + index + '.value'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入退款金额',
                        trigger: 'blur',
                      },
                      {
                        validator: function (rule, value, callback) {
                          itemCheckMoney(rule, value, callback, item);
                        },
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      style="width: 120px"
                      v-model="item.value"
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item>
                    <el-input
                      style="width: 120px"
                      v-model="item.value"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
          <el-form-item label="退款金额" prop="refundMoney" v-else>
            <el-input v-model="formInline.refundMoney"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="refund">确认退款</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    const checkMoney = (rule, value, callback) => {
      if (value > this.balance) {
        callback("退款金额不能大于余额");
      } else {
        return callback();
      }
    };
    return {
      dialogVisible: false,
      formInline: {
        radio: 3,
        refundMoney: "",
        orderList: "",
      },
      pageNum: 1,
      pageSize: 1000,
      orderList: [],
      rules: {
        radio: [
          {
            required: true,
            message: "请选择退款方式",
            trigger: "change",
          },
        ],
        refundMoney: [
          {
            required: true,
            message: "请输入退款金额",
            trigger: "blur",
          },
          {
            validator: checkMoney,
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    balance: {
      default: "0.00",
    },
    storeId: {
      required: true,
    },
    storeName: {
      required: true,
    },
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getRecord();
    },
    refund() {
      console.log("000000000000000");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {};
          if (this.formInline.radio == 3) {
            console.log(this.orderList, "this.orderList");
            data.storeId = this.storeId;
            data.refundItems = [];
            this.orderList.map((item) => {
              if ("value" in item && item.value != 0) {
                console.log(item, "item");
                data.refundItems.push({
                  rechargeOrderId: item.id,
                  money: item.value * 100,
                });
              }
            });
            data.refundType = "1";
          }
          if (this.formInline.radio == 6) {
            data.storeId = this.storeId;
            data.money = this.formInline.refundMoney * 100;
            data.refundType = "2";
          }
          console.log(data, "data--------");
          this.$axios
            .post(
              "/acb/2.0/storeRechargeRefund/refund",
              { data: data },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log("成功发送请求", response);

              // 处理后端返回的响应
              this.$alert(response.value, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
                callback: (action) => {
                  this.dialogVisible = false;
                },
              });
              // this.dialogVisible = false;
              this.getRecord();
            });
        } else {
          console.log("000000000000000");
          return false;
        }
      });
    },
    getRecord() {
      console.log(this.storeName, "--------------");
      this.$axios
        .get("/acb/2.0/storeRechargeDiscountRule/rechargeRefundRecord", {
          data: {
            storeName: this.storeName || "",
            transactionType: "2",
            // startTime: this.datetimerange[0],
            // endTime: this.datetimerange[1],
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.orderList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    itemCheckMoney(rule, value, callback, item) {
      if (!/^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
        callback("支持最多两位小数的正数");
      }
      if (parseFloat(value) > item.money) {
        callback("退款金额不能大于余额");
      }
    },
  },
};
</script>
<style scoped>
.tableTd {
  text-align: center;
  vertical-align: middle;
}
/deep/ .el-dialog {
  border-radius: 8px;
}
/deep/ .el-dialog__footer {
  text-align: center;
}
/deep/ .tableTd .el-form-item {
  margin: 10px 0;
}
/deep/ .tableTd .el-form-item__error {
  width: 100%;
  text-align: center;
}
</style>
